import React, { createContext, useState } from 'react'
import Sound from 'react-sound'

export const SoundContext = createContext()

const SoundContextProvider = (props) => {

    const [url, setUrl] = useState(null)
    const [play, setPlay] = useState(false)

    const [finished, setFinished] = useState(false)
    const [isPlaying, setIsPlaying] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null)
    const [activeButtonId, setActiveButtonId]  = useState(-1)

    const BUTTON_NO = 3

    const getActiveButtonId = (position, duration) => {

      // get active button id based on the audio current time
  
      let n = 0
  
      let t = position
  
      const x = duration / BUTTON_NO
  
      if (t <= x )
        return 1
  
      if (t <= 2 * x)
        return 2
  
      if (t < duration )
        return 3
  
      if (t >= duration)
        return 4
      
      return n
    }
  
    const onPlaying = (data) => {

      setIsPlaying(true)
  
       // monitor audio playback position to get active button
  
       const n = getActiveButtonId(data.position, data.duration)
  
       if (n !== activeButtonId)
         setActiveButtonId(n)
    }

    const onFinishedPlaying = () => {

      //console.log('SoundContext.onFinishedPlaying')

      setFinished(true)
      setIsPlaying(false)
    }

    const onError = (error) => {

      setErrorMessage(error)
    }

    const setSound = (url) => {

      setUrl(url)
      setActiveButtonId(-1)
      setPlay(false)
      setIsPlaying(false)
      setErrorMessage(null)
      setFinished(false)
    }

    const onLoad = () => {
      //console.log('SoundContext.onLoad')
    }

    return (
        <SoundContext.Provider value={{setSound, setPlay, isPlaying, finished, activeButtonId, errorMessage}}>

             <Sound
                url={url ? url : ''}
                playStatus={play && !finished ? Sound.status.PLAYING : Sound.status.PAUSED}
                onPlaying={onPlaying}
                onFinishedPlaying={onFinishedPlaying}
                onLoad={onLoad}
                onError={onError}
              />

            { props.children }

        </SoundContext.Provider>
    )
 }

 export default SoundContextProvider
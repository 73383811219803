import React from 'react';
import config from '../../config/config'
import branding from '../../config/branding'
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom";

import './header.scss'

export default function Header(props) {

  const history = useHistory()

  const onClick = (id) => {

    const path = '/' + id

    history.push(path)
  }

  const cochlearHeader = () => {

    const brand = branding[config.brandId]

    const logo = config.getImage(brand.logo)

    const mobilelogo = config.getImage(brand.mobilelogo)

    return <div className='cochlear-header'>

            <div className="desktop-header">

                <a href="#top" rel="noopener noreferrer">
                  <img className='text-left' src={logo} alt='logo' />
                </a>
              </div>

              <div className="mobile-header">
              
                <a href="#top" rel="noopener noreferrer" >
                  <img className='text-left' src={mobilelogo} alt='logo' />
                </a>
              </div>

                {
                  config.devMode?

                  <div className="hide-desktop">
                    <button type="button" className='btn  text-right' onClick={onClick.bind(this, 'practice')}>Practice</button>
                    <button type="button" className='btn  text-right' onClick={onClick.bind(this, 'test')}>Test</button>
                    <button type="button" className='btn  text-right' onClick={onClick.bind(this, 'result')}>Result</button>
                  </div>

                  :
                    null
                }

          </div>
    }

    const content = (config.brandId === 'cochlear') ? cochlearHeader() : null

    return <header className="app-header row">
              {content}
            </header>
}

Header.propTypes = {
  onHeaderClick: PropTypes.func
}

import config from '../config/config'
import 'isomorphic-fetch'

//import { getCookie } from '../tools/cookie'

export default class CochlearAPI {

  // endpoints

  // static mockLogin(username, password) {

  //   // mock auth API

  //   return new Promise((resolve, reject) => {

  //     setTimeout(() => {

  //       if (username === 'test' && password === 'test')
  //         resolve(true)
  //       else
  //         resolve(false)

  //     }, 500)
  //   })
  // },

  static login(username, password) {

    let url = config.apiURL + '/login'

    const data = {
      username,
      password
    }

    // api calls are not currently working for localhost:3000
    // cors needs to be confured on the server to allow this
    // to temporarily get around this problem use a proxy server

    // see: https://stackoverflow.com/questions/43871637/no-access-control-allow-origin-header-is-present-on-the-requested-resource-whe

    if (config.devMode)
      url = config.PROXY_SERVER + url

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then(res => res.json())
      .then(response => {
        //const res = JSON.stringify(response)
        //console.log(response)
        //console.log('Success:', res)

        return response
      })
  }  

  static setup(loginDetails) {

    let url = config.apiURL + '/setup'

    // const authorization = getCookie('authorization')
    // const authorizationRefresh = getCookie('authorization-refresh')

    const authorization = loginDetails.authorization
    const authorizationRefresh = loginDetails.authorizationRefresh

    if (config.devMode)
      url = config.PROXY_SERVER + url

    // let the caller catch errors

    return fetch(url,  {headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': authorization,
      'authorization-refresh': authorizationRefresh,
      //'Authorization': 'Basic ' + btoa(authorization)
    }})
      .then(res => res.json())
      .then(res => {

        return res
      })
  }

  static score(data, loginDetails) {

    let url = config.apiURL + '/score';

    // const authorization = getCookie('authorization')
    // const authorizationRefresh = getCookie('authorization-refresh')

    const authorization = loginDetails.authorization
    const authorizationRefresh = loginDetails.authorizationRefresh

    if (config.devMode)
      url = config.PROXY_SERVER + url

    // let the caller catch errors

    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': authorization,
          'authorization-refresh': authorizationRefresh,
        }
      })
      .then(res => res.json())
      .then(response => {
        //const res = JSON.stringify(response)
        //console.log('Success:', res)

        return response
      })
  }
}
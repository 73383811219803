import React from 'react'
import { getErrorMessage } from './formField'

import './formField.scss'


export default function TextField(props) {

  const { id, value, label, autofocus, errors, onChange, disabled, placeholder } = props

  let { type } = props

  if (!type)
    type = 'text'

  const changed = (e) => {
    onChange({id, value: e.target.value})
  }

  let errorMsg = getErrorMessage(id, errors)

  return (
    <div className={`form-group ${ errorMsg ? "has-error" : ""}`}>

      <label className='control-label' htmlFor={id}>

        {
          (label) ? label : <span>&nbsp;</span>
        }

      </label>

      <input id={id} 
        type={type} 
        className="form-control" 
        disabled={disabled} 
        onChange={changed} 
        value={value} 
        placeholder={placeholder}
        aria-label={label} 
        autoFocus={(autofocus)} 
        />

      { errorMsg ? <span className='error-msg' role='alert'>{errorMsg}</span> : null }

    </div>
    )
  }
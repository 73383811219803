import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom"
import Sound from 'react-sound'

import Loading from '../loading/loading'
import Dropdown from '../formField/dropdown'
import TextField from '../formField/textField'
import NextButton from '../nextButton/nextButton'
import config from '../../config/config'
import localise from '../../tools/localise'
import { SetupContext } from '../../contexts/setupContext'
import { ClinicContext } from '../../contexts/clinicContext'
import CochlearAPI from '../../services/cochlearAPI'
//import { createCookie, deleteCookie, getCookie } from '../../tools/cookie'
//import { SoundContext } from '../../contexts/soundContext'


import './details.scss'

export default function Details(props) {

  const { setSetup, loginDetails, setLoginDetails } = useContext(SetupContext)

  const t = 'details'

  let clinicIds = localise.getString(t + '.clinicIds')

  //const clinic = getCookie('clinic')  
  const clinic = loginDetails.clinic

  if (clinicIds.indexOf(clinic) === -1)
    clinicIds.push(clinic)

  const earItems = localise.getString(t + '.earItems')
  const audioSourceItems = localise.getString(t + '.audioSourceItems')
  const mapTypeItems = localise.getString(t + '.mapTypeItems')
  const testRunItems = localise.getString(t + '.testRunItems')

  const headingText = localise.getString(t + '.heading')
  const calibrateButtonText = localise.getString(t + '.calibrate')

  const clinicIdLabel = localise.getString(t + '.clinicId')
  const studyIdLabel = localise.getString(t + '.studyId')
  const patientIdLabel = localise.getString(t + '.patientId')
  const earLabel = localise.getString(t + '.ear')
  const audioSourceLabel = localise.getString(t + '.audioSource')
  const mapTypeLabel = localise.getString(t + '.mapType')
  const testRunLabel = localise.getString(t + '.testRun')
  const submitButtonLabel = localise.getString(t + '.submit')
  const pleaseEnter = localise.getString(t + '.pleaseEnter')

  // errors

  const enterPatientId = localise.getString(t + '.enterPatientID')
  const enterClinicId = localise.getString(t + '.enterClinicId')
  const enterEar = localise.getString(t + '.enterEar')
  const enterAudioSource = localise.getString(t + '.enterAudioSource')
  const enterMapType = localise.getString(t + '.enterMapType')
  const enterTestRun = localise.getString(t + '.enterTestRun')

  const [data, setData] = useState({
    clinicId: clinic,
    studyId: '',
    patientId: '',
    ear: earItems[0],
    audioSource: audioSourceItems[0],
    testRun: testRunItems[0],
    mapType: mapTypeItems[0],
    date: null
  })

  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)

  const history = useHistory()

  const { setClinic } = useContext(ClinicContext)
  //const { setSound, setPlay, isPlaying } = useContext(SoundContext)

  // const deleteCookies = () => {

  //   deleteCookie('clinic')
  //   deleteCookie('authorization')
  //   deleteCookie('authorization-refresh')
  // }  

  const load = async () => {

    // load setup data from API

    setLoading(true)
    setErrors([])

    let newErrors = []

    CochlearAPI.setup(loginDetails).then(res => {

      setLoading(false)

      if (res) {

        //console.log('Details.load: res: ', res)        

        //if (res.message === 'The incoming token has expired' || res.message === 'Unauthorized') {
        if (res.message && res.message.length > 0) {

          console.log('Details.load: error message: ', res.message)

          //deleteCookies()
          setLoginDetails(null)

          // go back to login, dont display error message

          history.push('/')
        }
        else {

          // update the authorization cookie
          // save setup in global context

          //console.log('updating authorization cookie: ', res.authorization)

          //createCookie('authorization', res.authorization, config.AUTHORIZATION_TOKEN_TIMEOUT_HOURS)
          setLoginDetails({clinic: loginDetails.clinic, 
            authorization: res.authorization,
            authorizationRefresh: loginDetails.authorizationRefresh
          })

          setSetup(res)
        }
      }
      else {

          // res is null, this should not happen, but just in case

          console.log('Details.load: login api response payload is null')
  
          //deleteCookies()
          setLoginDetails(null)

          // now when we refresh it will go back to login
  
          newErrors = [{id: 'systemError', message: 'empty payload returned from setup API call'}]
  
          setErrors(newErrors)
      }
    })
    .catch(err => { 

      // API error

      console.log('Details.load API error ', err)

      //deleteCookies()
      setLoginDetails(null)

      // now when we refresh it will go back to login

      newErrors = [{id: 'systemError', message: err.toString()}]

      setErrors(newErrors)
      setLoading(false)
    })
  }

  useEffect(() => {  

    setData({
        clinicId: clinic, //clinicIds[0],
        studyId: studyIdItems[0],
        patientId: '',
        ear: earItems[0],
        audioSource: audioSourceItems[0],
        testRun: testRunItems[0],
        mapType: mapTypeItems[0],
        date: null
      })

      load()

      const listener = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          onSubmit()
        }
      }

      document.addEventListener("keydown", listener)

      return () => {
        document.removeEventListener("keydown", listener)
      }

  }, [])

  const onChange = (newData) => {

    const d = {...data}   // clone
    d[newData.id] = newData.value

    setData(d)

    // setData is asynchronous, pass the new data so we check againt the latest

    let newErrors = validate(newData.id, d)

    if (newErrors.length === 0)
      clearError(newData.id)

    // if we change the audio source stop the audio

    if (newData.id === 'audioSource')
      setIsPlaying(false)
  }

  const onCalibrate = () => {

      setIsPlaying(!isPlaying)

      //const url = setup ? setup.audioFileUrl + '/' + //setup.calibrationFiles.audioFiles[0].file : null

      //const url = config.CALIBRATE_AUDIO_FILE

      //setSound(url)
      //setPlay(!isPlaying)
  }

  const onFinishedPlaying = () => {

    setIsPlaying(false)
  }

  const validate = (id, altData) => {

    const d = altData ? altData : data

    const rules = [
      {id: 'patientId', condition: (!d.patientId || d.patientId.length === 0), message: enterPatientId},
      {id: 'clinicId', condition: (d.clinicId === clinicIds[0]), message: enterClinicId},
      {id: 'ear', condition: (d.ear === earItems[0]), message: enterEar},
      {id: 'audioSource', condition: (d.audioSource === audioSourceItems[0]), message: enterAudioSource},
      {id: 'mapType', condition: (d.mapType === mapTypeItems[0]), message: enterMapType},
      {id: 'testRun', condition: (d.testRun === testRunItems[0]), message: enterTestRun}
    ]

    let newErrors = []

    if (!id) {

      // run all rules

      rules.forEach(x => {
        if (x.condition)
          newErrors.push({id: x.id, message: x.message})
      })
    }
    else {

      const rule = rules.find(x => x.id === id)

      if (rule) {

        if (rule.condition)
          newErrors.push({id: rule.id, message: rule.message})
      }
    }

    return newErrors
  }

  const clearError = (id) => {

    const newErrors = errors.filter(x => x.id !== id)

    setErrors(newErrors)
  }

  const onSubmit = async (e) => {

    if (e)
      e.preventDefault()

    let newErrors = validate()

    setErrors(newErrors)

    if (newErrors && newErrors.length > 0)
      return

    let newData = {...data}

    const dt = new Date()

    newData.date = dt.toString()

    setClinic(newData)

    history.push('/practice')
  }

  const studyIdItems = config.STUDY_IDS 

  //const src = setup ? setup.audioFileUrl + '/' + setup.calibrationFiles.audioFiles[0].file : null
  const url = config.CALIBRATE_AUDIO_FILE

  let calibrateButtonStyle = 'btn btn-compact btn-calibrate'
  
  if (isPlaying)
    calibrateButtonStyle += ' playing'

  if (data.audioSource === audioSourceItems[0] || data.audioSource === audioSourceItems[1])
    calibrateButtonStyle += ' not-visible'

  const systemError = errors && errors.find(x => x.id === 'systemError')

  return (

    <form className='details layout-1' onSubmit={onSubmit}>

      {
        url ?
          <Sound playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.PAUSED}  
                url={url} 
                onFinishedPlaying={onFinishedPlaying} />
        :
          null
      }
      
      {
        loading ? <Loading /> : null
      }

      <div className='content container'>

        {
          // only show calibrate button if not streaming

          <button type="button" className={calibrateButtonStyle} onClick={onCalibrate}>{calibrateButtonText}</button>
        }

        <div className='row'>
          <h1 className='col' align="center">{headingText}</h1>
        </div>
        
        {
          systemError ? <div className='row'>
                          <div className='col error' align="center">{systemError.message}</div> 
                        </div>
                          : null
        }

        <div className='row'>

          <div className='col-md-6'>
            <Dropdown id='clinicId' label={clinicIdLabel} value={data.clinicId} items={clinicIds} onChange={onChange} errors={errors} />
          </div>

          <div className='col-md-6'>
            <Dropdown id='studyId' label={studyIdLabel} value={data.studyId} items={studyIdItems} onChange={onChange} errors={errors} />
          </div>
          
        </div>

        <div className='row'>

          <div className='col-md-6'>
            <TextField id='patientId' label={patientIdLabel} value={data.patientId} onChange={onChange} errors={errors} placeholder={pleaseEnter} />
          </div>

          <div className='col-md-6'>
            <Dropdown id='ear' label={earLabel} value={data.ear} items={earItems} onChange={onChange} errors={errors} />
          </div>
          
        </div>

        <div className='row three-buttons'>

          <div className='col-md-6'>
            <Dropdown id='audioSource' label={audioSourceLabel} value={data.audioSource} items={audioSourceItems} onChange={onChange} errors={errors} />
          </div>

          <div className='col-md-3'>
            <Dropdown id='mapType' label={mapTypeLabel} value={data.mapType} items={mapTypeItems} onChange={onChange} errors={errors} />
          </div>

          <div className='col-md-3'>
            <Dropdown id='testRun' label={testRunLabel} value={data.testRun} items={testRunItems} onChange={onChange} errors={errors} />
          </div>

        </div>

        <div className='row'>
          <div className='col' align='center'>
            <NextButton label={submitButtonLabel} onClick={onSubmit}></NextButton>
          </div>
        </div>

      </div>

      

    </form>
  )
}

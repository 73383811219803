import React from 'react'
import { getErrorMessage } from './formField'

import './formField.scss'

export default function Dropdown(props) {

  const { id, value, label, items, errors, onChange, autofocus, disabled } = props

  let errorMsg = getErrorMessage(id, errors)

  const changed = (e) => {
    onChange({id, value: e.target.value})
  }

  return (
    <div className={`form-group ${ errorMsg ? "has-error" : ""}`}>

      <label className='control-label'>

        {
          (label) ? label : <span>&nbsp;</span>
        }

      </label>

      <select className="form-control" 
              id={id} 
              value={value} 
              onChange={changed} 
              autoFocus={(autofocus)} 
              disabled={(disabled)}>

        {
          items ? 
            items.map(x => <option key={x} value={x} >{x}</option> )
          :
            null
        }

      </select>

      { errorMsg ? <span className='error-msg' role='alert'>{errorMsg}</span> : null }

    </div>
    )
  }
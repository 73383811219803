import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/app/App'
//import * as serviceWorker from './serviceWorker';
//import TagManager from 'react-gtm-module'
import 'babel-polyfill'   // for IE11 support
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
//import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap-reboot.min.css'
import './index.scss';

// turn of react-sound debug messages

window.soundManager.setup({debugMode: false});

// const tagManagerArgs = {
//   gtmId: 'GTM-P8RS2XM',
// };
 
// TagManager.initialize(tagManagerArgs);

ReactDOM.render(

  <Router>
    <Switch>
      {/* <App /> */}
      {/* Redirect all app requests to AU to disable localisation */}
      <Route exact path='/LIT/en' 
        render={(props) => (<App {...props} lang='en' />)}
      />
      <Route exact path='/LIT/de' 
        render={(props) => (<App {...props} lang='de' />)}
      />
      <Route exact path='/LIT/es' 
        render={(props) => (<App {...props} lang='es' />)}
      />
      <Route exact path='/LIT/pt' 
        render={(props) => (<App {...props} lang='pt' />)}
      />
      <Route exact path='/LIT/it' 
        render={(props) => (<App {...props} lang='it' />)}
      />
      <Route exact path='/LIT/nl' 
        render={(props) => (<App {...props} lang='nl' />)}
      />
      <Route exact path="*" render={()=> <Redirect to="/LIT/en" />} /> 
      {/*<Route path="/:crap?/:country?" component={App}/>
      <Route path="/:country?" component={App}/> */}

    </Switch>
  </Router>, 

  // <App />,
  
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();

import React from 'react'
import { Text } from 'react-easy-i18n';
import PropTypes from 'prop-types'

import './nextButton.scss';

export default function NextButton(props) {

  const onClick = (e) => {

    if (props.onClick)
      props.onClick(e)
  }

  let { link, label, className, type } = props

  if (!className)
    className = ''

  if (!type)
    type = 'button'

  const ariaLabel =  (label) ? label : 'Next'

  if (link) {

    return <a href={link} 
              className='btn btn-next' 
              target='_blank' 
              rel="noopener noreferrer" 
              aria-label='next button'>

            <Text text={label} />

            <div className='arrow' />
          </a>
    }
    else {

        return <button aria-label={ariaLabel} 
                  type='button'
                  className={`btn btn-next ${className}`} 
                  onClick={onClick}>

                    <Text text={label} />
                    <span className='arrow'></span>
      
                </button>
  }
}

NextButton.propTypes = {
  onNext: PropTypes.func,
  link: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string
}
// branding data
// see common/config.js for brandId

const branding = {

  cochlear: {
    documentTitle: 'CI Screener',
    logo: 'cochlear-logo.svg',
    mobilelogo: 'Mobile-header-logo.svg',
    footerLogo: 'cochlear-footer.svg',
    contactUsTitle: 'go to cochlear website',
    email: 'hearinghelp@cochlear.com',
    phone: '1800 875 212',

    // links
    cochlearHome: 'https://www.cochlear.com/au/home',
    contactUsLink: 'https://www.cochlear.com/mobile_us/contact-us/australia',
    privacyPolicy: 'https://www.cochlear.com/intl/privacy-policy',
    termsOfUse: 'https://www.cochlear.com/intl/terms-of-use',
    oneEarAlternativeTest: 'https://www.cochlear.com/au/online-hearing-quiz',
    neverTellMeMore: 'https://www.cochlear.com/au/online-hearing-quiz',
    nal: 'https://www.nal.gov.au/project/language-independent-test-of-candidacy-for-cochlear-implants/',
    moreInformation: 'https://www.cochlear.com/au/home/understand/common-questions',
    getthefacts: 'https://www.cochlear.com/au/home/understand/hearing-and-hl/hl-treatments/cochlear-implant',
    findAClinic: 'https://www.cochlear.com/au/home/understand/find-a-clinic?mkt_tok=eyJpIjoiT1RVNU9ERmtaak0yT0RJMiIsInQiOiJNWkM1YzNYZEhVZk5ReXdCbEdqYjFYZEZqUG04MkpQXC9ndlBVc2ZpZUM0SUhpdFcwXC9NY29aZmZMT2cyTEY0Q1lWTjZOOWtWd29MSmFadmhlejhzbVdDNVdlQ2xVWnorQmlleE1ITHYyTjFDOXdUT3VUV3haXC96RzhwQmlQMTNHeCJ9',
    contactConcierge: 'https://www.cochlear.com/au/contact?mkt_tok=eyJpIjoiT1RVNU9ERmtaak0yT0RJMiIsInQiOiJNWkM1YzNYZEhVZk5ReXdCbEdqYjFYZEZqUG04MkpQXC9ndlBVc2ZpZUM0SUhpdFcwXC9NY29aZmZMT2cyTEY0Q1lWTjZOOWtWd29MSmFadmhlejhzbVdDNVdlQ2xVWnorQmlleE1ITHYyTjFDOXdUT3VUV3haXC96RzhwQmlQMTNHeCJ9'

  }
}

export default branding 
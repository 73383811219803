import React, { createContext, useState } from 'react'

export const ResultContext = createContext()

const ResultContextProvider = (props) => {

    const [result, setResult] = useState(null)

    const updateResult = (x) => {

      setResult(x)
    }

    return (
        <ResultContext.Provider value={{result, updateResult}}>
            { props.children }
        </ResultContext.Provider>
    )
 }

 export default ResultContextProvider

import React, { useState } from 'react';

import PracticeIntro1 from './intro1/intro1'
import PracticeIntro2 from './intro2/intro2'
import PracticeThanks from './thanks/thanks'
import QuestionController from '../question/questionController'
import Progress from '../progress/progress'
import { useHistory } from "react-router-dom"

import './practice.scss';

export default function Practice(props) {

  const [step, setStep] = useState('intro1')

  const history = useHistory()

  const onNext = () => {

    let nextStep

    switch ( step ) {
      default: nextStep = 'intro1'; break;
      case 'intro1': nextStep = 'intro2'; break
      case 'intro2': nextStep = 'practice';  break
      case 'practice': nextStep = 'thanks'; break

    case 'thanks':

        nextStep = 'end'
        history.push('/test')
        break
    }

    if (nextStep !== 'end')
      setStep(nextStep)
  }

  const onSkip = () => {
    
    //history.push('/test')
    setStep('thanks')
  }

  const getContent = () => {

    let content

    switch (step) {
      case 'intro1':
      default: 

        content = <PracticeIntro1 onNext={onNext} />
        break

      case 'intro2': 
      
        content = <PracticeIntro2 onNext={onNext} onSkip={onSkip} />
        break

      case 'practice': 
      
        content = <QuestionController type='practice' onNext={onNext} onSkip={onSkip} />
        break;

      case 'thanks': 

        content = <PracticeThanks onNext={onNext} />
        break
    }

    return content
  }

    let progress

    switch (step) {
      case "intro1": progress = 1; break;
      case "intro2": progress = 2; break;
      default:       progress = 2; break;
    }

    return (

      <div className='practice' role="main">

          <Progress phase={progress} />

          <div className='content'>
        
            { getContent() }

          </div>

      </div>
    )
}

Practice.propTypes = {

}
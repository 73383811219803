import React, { useState, useContext } from 'react'
import { useHistory } from "react-router-dom"

import { SetupContext } from '../../contexts/setupContext'
import { ResultContext } from '../../contexts/resultContext'
import { ClinicContext } from '../../contexts/clinicContext'
import CochlearAPI from '../../services/cochlearAPI'
import Question from './questionWithAudio'
import config from '../../config/config'
import Loading from '../loading/loading'
//import { createCookie, deleteCookie } from '../../tools/cookie'


export default function QuestionController(props) {

  const { type } = props

  const [ question, setQuestion ] = useState(0)
  const [ errorMsg, setErrorMsg ] = useState(null)

  const { setup, setSetup, loginDetails, setLoginDetails } = useContext(SetupContext)
  const { updateResult } = useContext(ResultContext)
  const { clinic } = useContext(ClinicContext)

  const history = useHistory()

  const debug = (msg) => {

    //console.log(msg)
  }

  // const deleteCookies = () => {

  //   deleteCookie('clinic')
  //   deleteCookie('authorization')
  //   deleteCookie('authorization-refresh')
  // }  

  const onSkip = () => {

    if (props.onSkip)
      props.onSkip()
  }

  const onNext = (selection, secondsToRespond) => {

    // a sound button was pressed

    // question: starts at 0
    // selection: 1..3
    // secondsToRespond: 
    // replays: how many times the sound was repeated

    //debug(`question: ${question} selection: ${selection} secondsToRespond: ${secondsToRespond}`)

    // dont display loading icon, too distracting

    //debug(' question = ' + question + ' selection = ' + selection + ' secondsToRespond = ' + secondsToRespond + ')

    const files = type === 'practice' ? setup.practiceFiles.audioFiles : setup.actualTestFiles.audioFiles

    let f = null

    if (question >= 0 && question < files.length) {

      f = files[question]
      
      f.selection = selection // 1..3, 0 is nothing selected

      if (config.autoAnswer)
        f.selection = f.correct

      f.secondsToRespond = secondsToRespond
      f.replays = 0 

      debug(f)
    }
    else {
      debug(`question index ${question} greater than number of files`)
    }

    // debug(f)
    // debug(files)

    // construct the payload for score endpoint

    const form = {
      patientId: clinic.patientId,
      clinicId: clinic.clinicId,
      testRun: clinic.testRun,
      studyId: clinic.studyId,
      ear: clinic.ear,
      audioSource: clinic.audioSource,
      date: clinic.date,
      mapType: clinic.mapType
    }

    const json = {
      version: setup.version,
      id: setup.id,
      form,
      practiceFiles: setup.practiceFiles,
      actualTestFiles: setup.actualTestFiles,
    }

    // save the current state
    // cochlearAPI.score is an asynchronous call so we set setup state before here rather than install the then

    setSetup(setup)

    // initialise the next question before waiting for server receipt response 
    setQuestion(question+1)

    CochlearAPI.score(json, loginDetails).then(res => {

      if (res) {
          
        debug('result: ')
        debug(res)
        // debug('action: ')
        // debug(res.action)

        if (res.message && res.message.length > 0) {

          // possible error messages:
          // The incoming token has expired
          // Unauthorized

          console.log('QuestionController.load: error message: ', res.message)

          //deleteCookies()
          setLoginDetails(null)

          // go back to login, dont display error message

          history.push('/')
        }
        else {

          // update the authorization cookie

          //createCookie('authorization', res.authorization, config.AUTHORIZATION_TOKEN_TIMEOUT_HOURS)
          setLoginDetails({clinic: loginDetails.clinic, 
            authorization: res.authorization,
            authorizationRefresh: loginDetails.authorizationRefresh
          })

          updateResult(res)
          
          switch (res.action) {
            case  'PRACTICE_CONTINUE': 
            case  'ACTUAL_TEST_CONTINUE': 

                // move to next question

                // setQuestion(question+1) // note: initialised question ahead of waiting for response
                setErrorMsg(null)
                break

            case  'START_ACTUAL_TEST': 

              // practice test finished, start test

              if (props.onNext) {
                props.onNext()
                return null
              }

              break

            case  'END_ACTUAL_TEST': 

              // test finished, goto results

              history.push('/result')
              return null

            default: 

              console.log('QuestionContoller: score API unexpected action: ', res.action)
              break
            } // switch
          }   // else
        } // if
    })  // then
    .catch(err => {

      console.log('QuestionContoller score API error: ', err.toString())

      //deleteCookies()
      setLoginDetails(null)

      setErrorMsg(err.toString())
    })
  }

  const files = type === 'practice' ? setup.practiceFiles : setup.actualTestFiles

  return <div className='question-container'>
            {
              errorMsg ?
                <div className='error'>{errorMsg}</div>
                :
                null
            }

            {
              question < files.audioFiles.length &&

                <Question type={type}   
                                question={question} 
                                audioFiles={files.audioFiles}
                                audioFileUrl={setup.audioFileUrl} 
                                onNext={onNext}
                                onSkip={onSkip} />    
            }
            {
                question === files.audioFiles.length &&
                <Loading />
            }
            {
                question > files.audioFiles.length &&
                <div className='error'>
                  QuestionController error: question number: {question} &gt;= number of audio files: {files.audioFiles.length} 
                </div>
              }

          </div>
}

import React, { useEffect } from 'react';
import { Text } from 'react-easy-i18n';
import NextButton from '../../nextButton/nextButton'
import PropTypes from 'prop-types'
import localise from '../../../tools/localise'

import './intro2.scss';

export default function PracticeIntro2(props) {

  useEffect(() => {  

    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        onNext()
      }
    }

    document.addEventListener("keydown", listener)

    return () => {
      document.removeEventListener("keydown", listener)
    }

  }, [])

  const onNext = () => {

    if (props.onNext)
      props.onNext()
  }

  const onSkip = () => {

    if (props.onSkip)
      props.onSkip()
  }

  const t = 'practice.intro2'

  return (

    <div className="step intro2 panel">

      <button className='btn btn-link btn-skip' onClick={onSkip} title={localise.getString(`${t}.skip`)}>
        <Text text={`${t}.skip`} />
      </button>
      
      <p className='subtitle'><Text className='t1' text={`${t}.t1`}/> 1 / 6</p>
      
      <h2><Text className='t2' text={`${t}.t2`} /></h2>

      <NextButton label={localise.getString(t + ".nextButton")} onClick={onNext}></NextButton>

      <Text text='practice.footnote' className="footnote smallfont subcopy bold"/>

    </div>
  )
}

PracticeIntro2.propTypes = {
  onNext: PropTypes.func
}
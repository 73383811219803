import React from 'react'
import { Text } from 'react-easy-i18n'
import SoundBars from './soundbars/soundbars'
import PropTypes from 'prop-types'
//import ClickableElement from '../clickableElement/clickableElement'
//import _ from 'lodash'

import './soundButton.scss';

//export class TouchButton extends ClickableElement {
  
// export class TouchButton extends Component {

//   render() {
    
//     const otherProps = _.omit(this.props, ['onClick', 'type']);
//     const t = 'soundButton'

//     return (
//       // <button
//       // onClick={e => this.onElementClicked(e)}
//       // type="button"
//       // {...otherProps}>

//       <button
//       onClick={_.debounce(this.props.onClick, 300)}
//       type="button"
//       {...otherProps}>

//       <Text text={`${t}.sound`} /> 
//       <span>&nbsp; {otherProps.id}</span>
//       </button>
//       );
//     }
//   }

const SoundButtonState = {
  UNPLAYED: 0,
  ACTIVE: 1,
  PLAYED: 2
};

export default function SoundButton(props) {

    const { id, canClick, activeButtonId, selected } = props

    const onClick = () => {

      if (props.onClick && canClick)
        props.onClick(id)
    }

    const getSoundButtonState = () => {

      let buttonState = SoundButtonState.UNPLAYED
    
      if (id === activeButtonId)
        buttonState = SoundButtonState.ACTIVE
      else
      if (activeButtonId > -1 && id < activeButtonId)
        buttonState = SoundButtonState.PLAYED
    
      return buttonState
    }

    const buttonState = getSoundButtonState()

    const t = 'soundButton'

    switch (buttonState) {
      case SoundButtonState.UNPLAYED: 
      default:

          return <button className='col-sm btn btn-sound disabled' disabled aria-disabled='true' >
                    <Text text={`${t}.sound`} /> &nbsp; {id}
                  </button>

      case SoundButtonState.ACTIVE: 

          return <button className='col-sm btn btn-sound active' disabled aria-disabled='true' >
                    <SoundBars animate={false} />
                    {/* <img className='soundbars-image' src={config.getImage('noun_Sound Wave_80779.svg')} alt='soundbars' /> */}
                </button>

      case SoundButtonState.PLAYED: 

        //return <TouchButton ref={'btn' + id} 
        // id={id} 
        // className={`col-sm btn btn-sound ${selected ? "selected": ""}`} 
        // onClick={this.onClick.bind(this)} />
    
          return <button id={id} 
                      type='button' 
                      className={`col-sm btn btn-sound ${selected ? "selected": ""}`} 
                      onClick={onClick} >
                        <Text text={`${t}.sound`} /> 
                        <span>&nbsp; {id}</span>
                  </button>
  }
}

SoundButton.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.number,
  buttonState: PropTypes.number,
  selected: PropTypes.bool
}

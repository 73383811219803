import React from 'react';
import { Text } from 'react-easy-i18n';
import NextButton from '../../nextButton/nextButton'
import localise from '../../../tools/localise'

import './thanks.scss';

export default function PracticeThanks(props) {

  const onNext = () => {

    if (props.onNext)
      props.onNext()
  }

  const t = 'practice.thanks'

  return (
    
    <div className="step thanks panel">

      <div className='content'>

        <h2><Text className='t1' text={`${t}.t1`}/></h2>
        
        <p>
          <Text className='t2' text={`${t}.t2`} />
          <br/>
          <Text className='t3' text={`${t}.t3`} />
          <br/>
          <Text className='t4' text={`${t}.t4`} />
          <br/>
          <br/>
          <Text className='t5' text={`${t}.t5`} />
          <br/>
          <Text className='t6' text={`${t}.t6`} />
          <br/>
          <Text className='t7' text={`${t}.t7`} />
        </p>

        <NextButton label={localise.getString(t + ".nextButton")} onClick={onNext}></NextButton>

      </div>
  
  </div>

  )
}

// functions common to all form fields

export function getErrorMessage(id, errors) {

  // search through list of errors for the error message for field id

  let errorMsg = null

  if (errors && Array.isArray(errors)) {

    let i =  errors.findIndex(x => x.id === id)

    if (i > -1)
        errorMsg = errors[i].message
  }

  return errorMsg
}

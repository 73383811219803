import React, { useEffect } from 'react';
import { Text } from 'react-easy-i18n';
import NextButton from '../../nextButton/nextButton'
import PropTypes from 'prop-types'
import config from '../../../config/config'
import localise from '../../../tools/localise'

import './intro1.scss';

export default function PracticeIntro1(props) {

  useEffect(() => {  

      const listener = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          onNext()
        }
      }

      document.addEventListener("keydown", listener)

      return () => {
        document.removeEventListener("keydown", listener)
      }

  }, [])

  const onNext = () => {

    if (props.onNext)
      props.onNext()
  }

  const t = 'practice.intro1'

  const cards = [
    {text: `${t}.t5`, icon: 'Icon_Purple_Clock1.svg'},
    {text: `${t}.t6`, icon: 'noun_Sound Wave_417929.svg'},
    {text: `${t}.t7`, icon: 'noun_Repeat_1523294.svg'}]

  return (

      <div className='step intro1'>

        <section className="panel" aria-label='region'>
        
          <section className='top' aria-label='region'>
          
            <p><Text className='t1' text={`${t}.t1`} /></p>
            
            <h2 className="mar-b-30"><Text className='t2' text={`${t}.t2`} /><br/><Text className='t2' text={`${t}.t21`} /><br/><Text className='t2' text={`${t}.t22`} /></h2>

            <p><Text className='t3' text={`${t}.t3`} /><br/><Text className='t4' text={`${t}.t4`} /></p>

            <NextButton label={localise.getString(t + ".nextButton")} onClick={onNext}></NextButton>

          </section>

        </section>    

        <section className='bottom' aria-label='region'>

          {
            cards.map(x => 
                    <article key={x.text} className='panel panel-border'>
                    <img src={config.getImage(x.icon)} className='icon' alt={x.text} />
                      <p><Text text={x.text} /></p>
                    </article>
            )
          }

        </section>

      </div>
  )
}

PracticeIntro1.propTypes = {
  onNext: PropTypes.func
}

import React, { useState, useContext } from 'react'
import { useHistory } from "react-router-dom";

import Loading from '../loading/loading'
import CochlearAPI from '../../services/cochlearAPI'
import TextField from '../formField/textField'
import localise from '../../tools/localise'
import NextButton from '../nextButton/nextButton'
import { SetupContext } from '../../contexts/setupContext'
//import { createCookie, deleteCookie } from '../../tools/cookie'
//import { withRouter } from 'react-router-dom'
//import PropTypes from "prop-types";


import './login.scss'

export default function Login(props) {

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)

  const { setLoginDetails } = useContext(SetupContext)

  const history = useHistory();

  // let propTypes = {
  //   match: PropTypes.object.isRequired,
  //   location: PropTypes.object.isRequired,
  //   history: PropTypes.object.isRequired
  // };

  // const { match, location, history } = props;

  const onChange = (newData) => {

    switch (newData.id) {
      case 'username': 
        setUsername(newData.value)
        break

      case 'password':
        setPassword(newData.value)
        break
        
      default:
    }
  }

  const validate = () => {

    let newErrors = []

    const t = 'login'

    if (!username || username.length === 0) 
      newErrors.push({id: 'username', message: localise.getString(t + '.missingUsername') })

    if (!password || password.length === 0) 
      newErrors.push({id: 'password', message: localise.getString(t + '.missingPassword') })

    return newErrors
  }

  // const deleteCookies = () => {

  //   deleteCookie('clinic')
  //   deleteCookie('authorization')
  //   deleteCookie('authorization-refresh')
  // }

  const onSubmit = async (e) => {

    e.preventDefault()

    let newErrors = validate()

    setErrors(newErrors)

    if (newErrors && newErrors.length > 0)
      return

    setLoading(true)
    setErrors([])

    CochlearAPI.login(username, password).then(res => {

      setLoading(false)

      if (res) {

        let newErrors = []

        //console.log('login statusCode: ', res.statusCode)

        switch (res.statusCode) {
          case 400:
                      
              // delete the cookies

              //deleteCookies()
              setLoginDetails(null)

              // in this case body has 1 of 3 values:
              // 'Missing username' (wont happen since we catch this in form validation)
              // 'Missing password' (wont happen since we catch this in form validation)
              // 'Incorrect username or password

              newErrors = [{id: 'systemError', message: localise.getString('login.invalidUsernamePassword')}]
              setErrors(newErrors)
              break

          case 200:

              // success

              //console.log('login successful:', res)

              const body = JSON.parse(res.body)
                
              //console.log(body)
    
              // store info in cookies
    
              // createCookie('clinic', body.clinic)
              // createCookie('authorization', body.authorization, config.AUTHORIZATION_TOKEN_TIMEOUT_HOURS)
              // createCookie('authorization-refresh', body['authorization-refresh'])

              setLoginDetails({clinic: body.clinic, 
                authorization: body.authorization,
                authorizationRefresh: body['authorization-refresh']
              })
    
              history.push('/')
              break

          default:

              // unhandled status code

              console.log('login.onSubmit unhandled status code: ', res.statusCode)

              // delete the cookies

              //deleteCookies()
              setLoginDetails(null)
              if (res.errorMessage && res.errorMessage.indexOf('UserNotFoundException')) {
                newErrors = [{id: 'systemError', message: localise.getString('login.invalidUsernamePassword')}]
                // newErrors = [{id: 'systemError', message: localise.getString('login.invalidUsernamePassword')}]
              } else {
                newErrors = [{id: 'systemError', message: 'Error: ' + res.errorMessage}]
              }
              // newErrors = [{id: 'systemError', message: 'statusCode: ' + res.statusCode}]
              setErrors(newErrors)
              break
        }
      }
      else {

        // res is null, this should not happen, but just in case

        console.log('login.onSubmit: login api response payload is null')

        // delete the cookies

        //deleteCookies()
        setLoginDetails(null)

        newErrors = [{id: 'systemError', message: 'empty payload returned from login API call'}]

        setErrors(newErrors)
      }
    })
    .catch(err => { 

      // API error

      console.log('login.onSubmit API error ', err)

      // delete the cookies

      //deleteCookies()
      setLoginDetails(null)

      const newErrors = [{id: 'systemError', message: err.toString()}]

      setErrors(newErrors)
      setLoading(false)
    })
  }

  const t = 'login'

  const systemError = errors && errors.find(x => x.id === 'systemError')

  return (

    <form className='login layout-1' onSubmit={onSubmit}>

      {
        loading ? <Loading /> : null
      }

      <div className='content'>

        <h1>{localise.getString(t + '.heading')}</h1>

        {
          systemError ? <div className='error'>{systemError.message}</div> : null
        }

        <TextField id='username' label={localise.getString(t + '.username')} value={username} onChange={onChange} errors={errors} />

        <TextField id='password' label={localise.getString(t + '.password')}  value={password} onChange={onChange} errors={errors} type='password' />

        <NextButton label={localise.getString(t + '.submit')} onClick={onSubmit}></NextButton>

      </div>

    </form>
  )
}

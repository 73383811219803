import React, {useState, useEffect} from 'react'
import { Text } from 'react-easy-i18n'
import config from '../../config/config'

import './loading.scss';

export default function Loading()  {
	const [delayFlag, setDelayFlag] = useState(false)
	useEffect(() => {
		const loaderSuspendTimer = setTimeout(
			() => {
				setDelayFlag(true)
			},
			500
		)
		return () => {
			clearTimeout(loaderSuspendTimer)
		}
	}, [])
	return  (
		<React.Fragment>
			{(delayFlag !== false) && (
				<div className='overlay'>
							<div className="loading">
								<img src={config.getImage('ajax-loader.gif')} alt='loading' />
								<Text text='loading' />
							</div>
						</div>
			)}
		</React.Fragment>
	)
}

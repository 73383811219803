import React from 'react';

import './soundbars.scss';

export default function SoundBars(props) {

    let { animate } = props

    var array = Array(10).fill("")

    return (
      <div className="soundbars">
        {
          array.map((x, i) => {
            let className = animate ? `bar bar${i+1}-animate` : 'bar'
            return <span className={className} key={i} ></span>
          })
        }
    </div>
    )
}
// config file

const config = {

    brandId: 'cochlear',                                                  // see common/branding.js for brandIds
    apiURL: process.env.REACT_APP_CI_API,                                 // cochlear api url
    imagePath: '/images/',                                                // folder where the images live
    defaultCountryCode: 'en',                                             // country code is normally taken from the url
    devMode: process.env.REACT_APP_DEV_MODE === 'true' ? true : false,    // set to true for development
    autoAnswer: false,                                                    // answer questions automatically with no wait, useful for development
    BUFFER_TIME: 10,         // how long to wait before playback starts
    STUDY_IDS: ["AI5769", "AI5781 SASC", "AI5806 NEUROFI", "AI5817 OFIT", "AI5841A", "IIR_2335", "IIR_2336"],
    CALIBRATE_AUDIO_FILE: '/audio/CalNoise.wav',
    REACT_APP_STUDY_IDS: process.env.REACT_APP_STUDY_IDS,
    PROXY_SERVER: '', //"https://cors-anywhere.herokuapp.com/",   // set this to "" turn not use proxy server
    AUTHORIZATION_TOKEN_TIMEOUT_HOURS: 1,
    //env: process.env.REACT_APP_MARKETO_ENV,  // SIT, UAT or PRD
    //LOGIN_COOKIE_TIMEOUT_HOURS: 24,

    getImage: (image) => {

      return config.imagePath + image
    }
  }

  export default config

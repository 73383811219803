import React, { useState, useEffect, useContext } from 'react'
import { MemoryRouter, Switch, Route, Redirect } from 'react-router-dom'
import 'babel-polyfill'

import Header from '../header/header'
import Footer from '../footer/footer'
import Login from '../login/login'
import Details from '../details/details'
import Practice from '../practice/practice'
import Test from '../test/ciTest'
import Result from '../result/result'
import PropTypes from 'prop-types'
import localise from '../../tools/localise'
import config from '../../config/config'
import { SetupContext } from '../../contexts/setupContext'
//import { getCookie } from '../../tools/cookie'

import SetupContextProvider from '../../contexts/setupContext'
import ClinicContextProvider from '../../contexts/clinicContext'
import ResultContextProvider from '../../contexts/resultContext'
import SoundContextProvider from '../../contexts/soundContext'

import './App.scss'

(function (w) {

  w.URLSearchParams = w.URLSearchParams || function (searchString) {
      var self = this;
      self.searchString = searchString;
      self.get = function (name) {
          var results = new RegExp('[?&]' + name + '=([^&#]*)').exec(self.searchString);
          if (results == null) {
              return null;
          }
          else {
              return decodeURI(results[1]) || 0;
          }
      };
  }

})(window)

// redirect to login screen if not logged in
// see: https://stackoverflow.com/questions/47476186/when-user-is-not-logged-in-redirect-to-login-reactjs

const CheckRoute = ({ component: Component, ...rest }) => {

  const { loginDetails } = useContext(SetupContext)

  const isLoggedIn = () => {

    return loginDetails !== null

    //return getCookie('authorization')
  }

  return (
    <Route
      {...rest}
      render={props =>
        isLoggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
        )
      }
    />
  )
}


export default function App(props) {

  const [initialised, setInitialised] = useState(false)

  useEffect(() => {

    let country = config.defaultCountryCode

    // if (props && props.match) {
      
    //   if ( props.match.path === "/LIT/de") country = "de";
    //   //if (props.match.params.crap && props.match.params.country)
    //   //  country = props.match.params.country
    //   //else
    //   //if (props.match.params.crap && !props.match.params.country)
    //   //  country = props.match.params.crap
    // }

    //console.log('country = ', country)
    
    // set language for text
    localise.init(props.lang || country)

    // set the document language for screen readers

    document.documentElement.setAttribute('lang', localise.code)

    // right to left rendering

    if (localise.rtl)
      document.documentElement.setAttribute('dir', 'rtl')

    setInitialised(true)    // force redraw

  }, [])

  let classNames = ['page', 'container-fluid']

  return initialised ?

    <main className={classNames.join(' ')}>

      <MemoryRouter initialEntries={['/']} initialIndex={0}> 

        <Header />

          <div className='app-body row'>

            <div className='container body-content'>

              <SetupContextProvider>
                <ClinicContextProvider>
                  <ResultContextProvider>

                    <SoundContextProvider >

                    <Switch>    

                      <Route path='/login' component={Login} />
                      
                      <CheckRoute path="/details" component={Details} />
                      <CheckRoute path="/test" component={Test} />
                      <CheckRoute path="/practice" component={Practice} />
                      <CheckRoute path="/result" component={Result} />
                      <CheckRoute path="/" component={Details} />

                    </Switch>  

                    </SoundContextProvider>

                  </ResultContextProvider>
                </ClinicContextProvider>
              </SetupContextProvider>

            </div>

          </div>

        </MemoryRouter> 

        <Footer />

      </main>

    :
    null
}

App.propTypes = {
  match: PropTypes.object
};

import React, { createContext, useState } from 'react';

export const ClinicContext = createContext()

const ClinicContextProvider = (props) => {

    const [clinic, setClinic] = useState(null)

    return (
        <ClinicContext.Provider value={{clinic, setClinic}}>
            { props.children }
        </ClinicContext.Provider>
    )
 }

 export default ClinicContextProvider
import React from 'react'
import QuestionController from '../question/questionController'
import Progress from '../progress/progress'
import { useHistory } from "react-router-dom"

import './test.scss'

export default function Test(props) {

  const history = useHistory()

  const onNext = () => {

    history.push('/result')
  }
  
  return (
    <section className='test' aria-label='setup' role="main">

        <Progress phase={3} />

        <div className='content'>
          <QuestionController type='test' onNext={onNext} />
        </div>
        

    </section>
  )
}

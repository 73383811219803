import React, { createContext, useState } from 'react';

export const SetupContext = createContext()

const SetupContextProvider = (props) => {

    const [setup, setSetup] = useState(null)
    const [loginDetails, setLoginDetails] = useState(null)

    return (
        <SetupContext.Provider value={{setup, setSetup, loginDetails, setLoginDetails}}>
            { props.children }
        </SetupContext.Provider>
    )
 }

 export default SetupContextProvider
import React from 'react';
import { Text } from 'react-easy-i18n';
import config from '../../config/config'
import branding from '../../config/branding'
import localise from "../../tools/localise";

import './footer.scss'

export default function Footer(props) {

    const brand = branding[config.brandId]
    const footerLogo = config.getImage(brand.footerLogo)

    const t = 'footer'

    return (
      <footer className="app-footer row">
        <section className='cochlear-footer container-fluid' aria-label='page footer'>

        <div className='content'>

          <div className='footer-logo'>
            <img src={footerLogo} alt='logo' />
            <Text className='logotag' text={`${t}.Clinical use only`} /> 
          </div>

          <div className="footer-links">

            <a className='flex-nowrap'
	      href={localise.getString(t + '.privacyPolicyLink')}
              target="_blank" 
              rel="noopener noreferrer" >
              <Text text={`${t}.privacy`} /> 
            </a>

            <a className='flex-nowrap'
	      href={localise.getString(t + '.termsOfUseLink')}
	      target="_blank" 
              rel="noopener noreferrer" >
              <Text text={`${t}.termsOfUse`} /> 
            </a>
          </div>

          <div className='copyright'><Text text={`${t}.copyright`} /></div>

        </div>

        </section>
        
      </footer>
    )
}

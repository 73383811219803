import React, { useState, useEffect, useRef } from 'react'
import { Text } from 'react-easy-i18n'
import PropTypes from 'prop-types'
import _ from 'lodash'

import Loading from '../loading/loading'
import SoundButton from '../soundButton/soundButton'
import config from '../../config/config'
import NextButton from '../nextButton/nextButton'
import localise from '../../tools/localise'

import './question.scss'

export default function Question(props) {

  const BUTTON_NO = 3
  let bufferTimerId = null

  // only put things in state which affect redraw

  // we need to set play to true initially otherwise we get an audio error
  // issue to do with html audio on ios safari
  // audio needs to load
  
  const [selectedButtonId, setSelectedButtonId] = useState(-1)
  const [activeButtonId, setActiveButtonId]  = useState(-1)
  const [errorMessage, setErrorMessage]  = useState(null)
  const [timestamp, setTimestamp] = useState(0)
  const [questionAudioLoaded, setQuestionAudioLoaded] = useState(false)
  const [nextQuestionAudioLoaded, setNextQuestionAudioLoaded] = useState(false)

  const audioPlayer = useRef()

  // see https://upmostly.com/tutorials/settimeout-in-react-components-using-hooks

  const selectedButtonIdRef = useRef(selectedButtonId);
  selectedButtonIdRef.current = selectedButtonId;

  const activeButtonIdRef = useRef(activeButtonId);
  activeButtonIdRef.current = activeButtonId;

  const { type, question, audioFileUrl, audioFiles } = props

  const debug = (msg) => {
    //console.log(msg)
  }

  useEffect(() => { 

    debug('Question.useEffect: question: ', question)

    // go to initial state

    killTimers()

    setSelectedButtonId(-1)
    setActiveButtonId(-1)
    setErrorMessage(null)
    //setCanPlayThrough(false)

    // Manually calling this because iOS safari won't trigger events 
    // if the audio src is same as previous one
    audioPlayer.current.load();

    startBufferTimer()

    const listener = event => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        onNext()
      }
    }
    
    listener.bind(this)

    document.addEventListener("keydown", listener)

    return () => {

      // unmount

      debug('Question.unmount')

      document.removeEventListener("keydown", listener)

      killTimers()
    }
  }, [props.question])

  const getActiveButtonId = (position, duration) => {

    // get active button id based on the audio current time

    let n = 0
    let t = position

    const x = duration / BUTTON_NO

    if (t <= x )
      return 1

    if (t <= 2 * x)
      return 2

    if (t < duration )
      return 3

    if (t >= duration)
      return 4
    
    return n
  }

  const onTimeUpdate = () => {

    const n = getActiveButtonId(audioPlayer.current.currentTime, audioPlayer.current.duration)

      // active sound has changed

    if (n !== activeButtonId)
       setActiveButtonId(n) 

    if (audioPlayer.current.currentTime === audioPlayer.current.duration) {

      debug('onTimeUpdate onEnded ', audioPlayer.current.duration)

      // // set the timestamp to see how long it takes for the user to answer

      const t = new Date().getTime()
  
      setTimestamp(t)
  
      if (config.autoAnswer)
        props.onNext(1, 0)
    }
  }

  const onPlay = () => {

    audioPlayer.current.play()
  }

  const startBufferTimer = () => {
    
    // wait before starting playback

    if (bufferTimerId)   // timer already running
      return

    debug('startBufferTimer: start')

    bufferTimerId = setTimeout(() => {

      debug('startBufferTimer: end')

      bufferTimerId = null

      onPlay()
    }, config.autoAnswer ? 0 : config.BUFFER_TIME)
  }

  const killTimers = () => {

    // kill timer

    if (bufferTimerId) {
      clearTimeout(bufferTimerId) 
      bufferTimerId = null
    }
  }

  const onSelect = (id) => {

    // user selected a sound

    debug('onSelect: id: ' + id)
    setSelectedButtonId(id)
  }

  const onNext = () => {

    // because onNext can be triggered from the enter key press listener
    // we need to use refs to make sure we ge the correct values of state variables
    // see: https://upmostly.com/tutorials/settimeout-in-react-components-using-hooks

    // user pressed the next button
    // cant click until all timers have finished and active button number is 3

    if (props.onNext && activeButtonIdRef.current > BUTTON_NO && selectedButtonIdRef.current > 0) {

      let t = new Date().getTime()
      
      t -= timestamp    // subtract time since test question started
      t /= 1000         // convert to seconds

      props.onNext(selectedButtonIdRef.current, t)
      setSelectedButtonId(-1)    // hide next button immediately
      setActiveButtonId(-1)       // we nned this so the buttons will go grey immediatley
      setQuestionAudioLoaded(false)
      setNextQuestionAudioLoaded(false)
    }
  }

  const onLoad = () => {

    // audio file loaded

    debug('onLoad: audio loaded');
    setQuestionAudioLoaded(true);
    // if (!canPlayThrough)
    //   setCanPlayThrough(true)
  }

  const onLoadNext = () => {

    // audio file loaded

    debug('onLoad: next audio loaded');
    setNextQuestionAudioLoaded(true);
    // if (!canPlayThrough)
    //   setCanPlayThrough(true)
  }

  const onError = (errorCode, description) => {

    // audio error

    debug('Question.error: ' + errorCode + ' ' + description)

    setErrorMessage(description)
  }

  const onSkip = () => {

    if (props.onSkip)
      props.onSkip()
  }

  var buttons = Array(BUTTON_NO).fill(0)

  const url = question >= 0 && question < audioFiles.length ? audioFileUrl + '/' + audioFiles[question].file : ''
  const nextAudioUrl = (question + 1) >= 0 && (question + 1) < audioFiles.length ? audioFileUrl + '/' + audioFiles[(question + 1)].file : ''

  const n = audioFiles.length

  const t = type === 'practice' ? 'practice.question' : 'test.question'

  return (
      <div className='step question panel'>

          {
              type === 'practice' ?
                <button className='btn btn-link btn-skip' onClick={onSkip} title={localise.getString(`${t}.skip`)}>
                  <Text text={`${t}.skip`} />
                </button>
                :
                null
          }

          <p className='subtitle'><Text text={`${t}.count`}/> {question+1} of {n} </p>

          <h2 className='title'><Text text={`${t}.title`} /></h2>

          {
            type === 'practice' ?
              <h3 className="subtitle"><Text text={`${t}.subtitle`} /></h3>
              :
              null
          }

          <Text text={`${t}.helpText`} className="help-text"/>

          {
            errorMessage ?
              <div className='error'>{errorMessage}</div>
              :
              null
          }

          <section className='sound-buttons' aria-label='region'>

			<React.Fragment>
            {
              buttons.map((id, i) => {
              
                return <SoundButton key={i} 
                      id={i+1} 
                      selected={selectedButtonId === i+1} 
                      activeButtonId={activeButtonId}
                      canClick={activeButtonId > BUTTON_NO}   // all sounds played
                      onClick={onSelect} />                  
              })
            }
			</React.Fragment>
		  {/* Show loader while sound is downloading */}
		  {(questionAudioLoaded !== true) && (
			  <React.Fragment>
				  <Loading />
			  </React.Fragment>
		  )}

          </section>

          <section>

            {
              selectedButtonId > 0 ?
                <NextButton label={localise.getString(t + ".nextButton")} onClick={_.debounce(onNext, 100)}></NextButton>
                :
                <div className='btn-spacer'>&nbsp;</div>
            }

          </section>

            {
              type === 'practice' ?
                <Text text='practice.footnote' className="footnote smallfont subcopy bold"/>
                :
                  selectedButtonId > 0 ?
                    <Text text='test.question.footnote' className="footnote smallfont subcopy bold"/>
                    :
                    <div className='footnote-text-spacer'>&nbsp;</div>
            }

        {
        // load audio for current question
        }
        <audio 
          ref={audioPlayer}
          src={url}
          autoPlay={false}
          preload="auto"
          onError={onError} 
          onTimeUpdate={onTimeUpdate}
          onCanPlayThrough={onLoad} >
        </audio>
        {
        // Preload audio from the next question
        (nextAudioUrl !== '' && questionAudioLoaded === true && nextQuestionAudioLoaded !== true) && (
            <audio 
                src={nextAudioUrl}
                autoPlay={false}
                preload="auto"
				onCanPlayThrough={onLoadNext} >
            </audio>
        )}
      </div>
  )
}

Question.propTypes = {
  audioFiles: PropTypes.array,
  audioFileUrl: PropTypes.string,
  question: PropTypes.number.isRequired,
  onNext: PropTypes.func,
  onError: PropTypes.func,
}

import React, { useEffect, useContext} from 'react'
import { useHistory } from "react-router-dom"

import localise from '../../tools/localise'
import { ResultContext } from '../../contexts/resultContext'
import { SetupContext } from '../../contexts/setupContext'
import config from '../../config/config'
//import { deleteCookie } from '../../tools/cookie'

import './result.scss'

// const cards = [
//   {icon: 'tick-icon.png'},
// ]

export default function Result(props) {

  const history = useHistory()

  const { result } = useContext(ResultContext)
  const { setLoginDetails } = useContext(SetupContext)

  const t = 'result'

  useEffect(() => {

    console.log('Result: ', result)
  })

  // const deleteCookies = () => {

  //   deleteCookie('clinic')
  //   deleteCookie('authorization')
  //   deleteCookie('authorization-refresh')
  // }  

  const onStartAgain = () => {

    // logout and start again

    //deleteCookies()
    setLoginDetails(null)
    history.push('/')
  }

  return (

      <div className='result layout-1'>

        <div className='content'>

          <button type="button" className='btn btn-compact btn-start-again' onClick={onStartAgain}>
            {localise.getString(t + '.startAgain')}
          </button>

          <section className='resulttext' aria-label='region'>

          {/* {
            cards.map((x,i) => 
                    
                    <img key={i} src={config.getImage(x.icon)} className='icon' alt={x.text} />
            )
          } */}

          <img src={config.getImage('tick-icon.png')} className='icon' alt='tick-icon' />

          </section>

            <h1>{localise.getString(t + '.title')}</h1>
{/* 
            <p className='line'></p> */}

          </div>

      </div>
  )
}
